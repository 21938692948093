<template>
  <v-app>
    <v-container>
      <v-row class="mt-16">
        <v-col>
          <h3 class="text-left">
            Approved Analysis Plans
          </h3>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <p class="text-left text-justify">
            We actively maintain an up to date overview of all approved analysis
            plans. In order to avoid duplicate work, please consult the list of
            analysis plans that are already approved, before you submit an
            anlysis plan of your own.
          </p>
          <p class="text-left text-justify">
            Use the button below to go to the database of approved analysis
            plans.
          </p>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <v-col>
          <v-btn
            class="mb-10 mt-5"
            :loading="loading"
            :disabled="loading"
            color="primary"
            @click="goToPlans"
          >
            Approved Analysis Plans
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  methods: {
    goToPlans() {
      window.open(
        "https://surfdrive.surf.nl/files/index.php/s/LoIYIKx14CO408g",
        "_blank"
      );
    },
  },
};
</script>
